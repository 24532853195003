html,
body {
  height: 100%;
}
body {
  font-family: 'lato';
  background: #191a2c !important;
  font-family: 'open-sans';
  height: auto;
  min-height: 100%;
  position: relative;
}

h1, h2, h3, h4, h5, h6 {
  color: white!important;
}
body .checkbox label,
body .radio label,
body label {
  font-family: 'open-sans';
  font-weight: bold;
  font-size: 12px;
  line-height: 12px;
  color: #666;
  text-transform: uppercase;
  margin-bottom: 0;
}
.center {
  max-width: 1300px;
  width: 100%;
  margin: 0 auto;
}

.grid {
  display: grid;
}
.flex {
  display: flex;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.space-between {
  justify-content: space-between;
}

.btn.btn-maior {
  border-radius: 8px;
  color: #fff;
  background: #29bbf5;
  font-family: 'open-sans-bold';
  font-size: 16px;
  border-radius: 8px;
  padding: 16px 25px;
}
.btn.btn-maior:hover,
.btn.btn-maior:focus,
.btn.btn-maior:active,
.btn.btn-maior:active:focus {
  background: #8d45ae;
  color: #fff;
}

.btn.btn-maior i {
  font-size: 18px;
  position: relative;
  left: -9px;
}
.btn-maior.cancel {
  background: #a82200;
}
.btn.btn-maior.cancel:hover,
.btn.btn-maior.cancel:focus,
.btn.btn-maior.cancel:active,
.btn.btn-maior.cancel:active:focus {
  background: #651400;
  color: #fff;
}

.btn.btn-maior.btn-outline {
  font-family: 'open-sans-bold';
  font-size: 16px;
  border-radius: 8px;
  padding: 13px 25px;
  display: inline-block;
}
.btn.btn-padrao {
  border-radius: 8px;
  color: #111322;
  background: #29bbf5;
  font-family: 'open-sans-bold';
}
.btn.btn-padrao:hover,
.btn.btn-padrao:focus,
.btn.btn-padrao:active,
.btn.btn-padrao:active:focus {
  background: #296cee;
}
.btn.btn-outline {
  border-radius: 8px;
  background: transparent;
  border: 2px solid #29bbf5;
  color: #29bbf5;
  font-family: 'open-sans';
  padding: 10px 20px;
  font-size: 13px;
  display: flex;
  align-items: center;
}

.btn.btn-outline:hover,
.btn.btn-outline:focus,
.btn.btn-outline:active,
.btn.btn-outline:active:focus {
  background: #29bbf5;
  color: #191a2c;
  font-weight: 700;
}
.btn.btn-outline:hover i,
.btn.btn-outline:focus i,
.btn.btn-outline:active i,
.btn.btn-outline:active:focus i {
  color: #fff;
}
.btn.btn-outline i {
  margin-right: 6px;
}
.form-control,
.custom-file-control,
.is-focused .form-control,
.is-focused .custom-file-control {
  background-image: linear-gradient(
      to top,
      #29bbf5 2px,
      rgba(63, 81, 181, 0) 2px
    ),
    linear-gradient(to top, rgba(0, 0, 0, 0.26) 1px, transparent 1px);
  background-image: none !important;
}
.btn.active {
  color: #fff !important;
  background: #29bbf5;
}
.btn.btn-link,
.btn.btn-link:hover {
  color: #29bbf5;
  text-decoration: none;
  border: 0;
}
.btn.btn-link:hover {
  color: #296cee;
  border: 0;
  background: transparent;
}
.btn.btn-link.active:focus {
  color: #296cee;
  background: transparent;
}
body p {
  font-family: 'open-sans';
  font-size: 12px;
  line-height: 14px;
  text-align: left;
}
body a {
  color: #29bbf5;
}
body a:hover {
  color: #8d45ae;
  text-decoration: none !important;
}

.user {
  overflow: hidden;
  border-radius: 500px;
  width: 48px;
  height: 48px;
  min-width: 48px;
  min-height: 48px;
  display: block;
}
.user img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
}
.user.menor {
  width: 32px;
  height: 32px;
  min-width: 32px;
  min-height: 32px;
}
.user-group {
  display: inline-flex;
  align-items: center;
  color: #666 !important;
}
.btn {
  border-radius: 8px;
}
.user-group .user {
  margin-right: 7px;
}
h2 {
  font-family: 'open-sans-light';
}
.logo-icon:after {
  content: ' ';
  display: inline-block;
  width: 22px;
  height: 16px;
}
.tab-pane {
  display: none;
}
.tab-pane.active {
  display: block;
}
.dropdown-menu {
  padding: 0;
}

/* ===========UPLOAD IMAGE================== */

.avatar-upload {
  position: relative;
  max-width: 129px;
  margin: 0 auto;
  margin-bottom: 40px;
  cursor: pointer;
  text-align: center;
}
.avatar-upload span {
  color: #5b5957;
}
.avatar-upload .avatar-edit {
  position: absolute;
  right: 0;
  z-index: 1;
  bottom: 10px;
  cursor: pointer;
}
.avatar-upload input {
  width: 129px;
  height: 129px;
  position: absolute;
  cursor: pointer;
  opacity: 0;
  z-index: 1;
  left: 0;
  bottom: 0;
}

.avatar-upload .avatar-edit label {
  width: 32px;
  height: 32px;
  margin-bottom: 0;
  border-radius: 100%;
  background: #5b5957;
  display: flex;
  color: #fff;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.avatar-upload .avatar-edit input + label:hover {
  /* background: #f1f1f1;
    border-color: #d6d6d6; */
}

.avatar-upload .avatar-preview {
  width: 129px;
  height: 129px;
  position: relative;
  border-radius: 100%;
}
.avatar-upload .avatar-preview > div {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.avatar-upload .avatar-preview .image {
  background-image: url('/images/user.svg');
  background-repeat: no-repeat;
  background-size: cover;
}

.upload-arquivo {
  padding: 2vw;
  max-width: 700px;
  margin: 0 auto;
}
.upload-arquivo p {
  text-align: center;
  font-size: 15px;
  line-height: 20px;
}
.file-upload-wrapper {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  margin-bottom: 15px;
}
.file-upload-wrapper:before {
  content: attr(data-text);
  font-size: 24px;
  top: 0;
  left: 0;
  padding: 10px 15px;
  display: block;
  pointer-events: none;
  z-index: 20;
  height: 40px;
  line-height: 40px;
  color: #999;
  border-radius: 5px 10px 10px 5px;
  font-weight: 300;
  text-align: center;
}
/* .file-upload-wrapper:after {
    position: relative;
  
    display: inline-block;
    height: 200px;
    width: 200px;
    color: #a9a9a9;
    z-index: 25;
    line-height: 60px;
    padding: 0 15px;
    text-transform: uppercase;
    pointer-events: none;
    border-radius: 0 5px 5px 0;
    border: 9px dashed #a9a9a9;
    content: "\f067";
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    font-size: 50px;
} */
/* .file-upload-wrapper:hover:after {
    background: #d2d2d2;
} */
.file-upload-wrapper input {
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  margin: 0;
  padding: 0;
  display: block;
  cursor: pointer;
  width: 200px;
  height: 100%;
  margin: 0 auto;
}
.check {
  position: relative;
  /* top: 0; */
  /* right: 0; */
  display: inline-block;
  height: 200px;
  width: 200px;
  color: #a9a9a9;
  z-index: 25;
  line-height: 60px;
  padding: 0 15px;
  text-transform: uppercase;
  pointer-events: none;
  border-radius: 0 5px 5px 0;
  border: 9px dashed #a9a9a9;

  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;

  -webkit-transition: all 2s ease-out;
  -moz-transition: all 2s ease-out;
  -o-transition: all 2s ease-out;
  transition: all 2s ease-out;
}
.check:before {
  content: '\f067';
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  font-size: 50px;
}
.check:after {
  font-size: 0px !important;
  content: '\f00c';
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  font-size: 50px;
  -webkit-transition: all 2s ease-out;
  -moz-transition: all 2s ease-out;
  -o-transition: all 2s ease-out;
  transition: all 2s ease-out;
}
.upload-arquivo input[type='file']:valid ~ .check {
  border: 9px solid #65c566;
  color: #116525;

  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
  background: #65c566;
  border: 0;
}
.upload-arquivo input[type='file']:valid ~ .check:before {
  display: none;
}
.upload-arquivo input[type='file']:valid ~ .check:after {
  font-size: 80px !important;
  color: #418651;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
/* =============================TOPO=============================== */
.topo {
  background: #191a2c;
  height: 66px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
}
.topo .navbar-nav {
  align-items: center;
  flex-direction: initial;
  width: 100%;
  display: flex;
  justify-content: inherit;
}
.topo .navbar {
  height: 66px;
}
.topo .navbar-collapse {
}
.topo .navbar-light .navbar-nav .nav-link {
  text-transform: uppercase;
  font-size: 12px;
  line-height: 14px;
  color: #fff;
  cursor: pointer;
}
.topo .navbar-light .navbar-nav .nav-link:hover {
  color: #8d45ae;
}
.topo .btn {
}
.topo .btn {
  height: 31px;
  padding-top: 0;
  padding-bottom: 0;
}
.topo .navbar-brand {
  padding: 1px 12px;
  margin-right: 6px;
}
.topo .navbar-brand img {
  max-width: 140px;
}
.topo .actions {
  display: flex;
}
.topo .actions i {
  color: #29bbf5;
}
.actions > * {
  margin: 0 8px;
}
.topo .actions .user {
  overflow: hidden;
  width: 32px;
  height: 32px;
  border-radius: 50px;
}
.topo .actions .user img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
}

.topo .btn.pmd-btn-fab.btn-sm {
  width: 32px;
  min-width: 32px;
  height: 32px;
}
.topo .btn.pmd-btn-fab.btn-sm span,
.topo .btn.pmd-btn-fab.btn-sm i {
  line-height: 32px;
}
.topo .busca {
  display: flex;
  margin: 0;
  height: 35px;
}
.collapse-busca .card {
  background: #fff;
  padding: 16px;
  display: flex;
  flex-direction: inherit;
  flex-flow: row wrap;
}
.collapse-busca .btn {
  width: 100%;
  height: 40px;
}
.topo .botoes {
  display: flex;
  margin: 0;
}

.topo .btn {
  margin: 0 7px;
}
.topo .btn-group {
  margin: 0;
}
.topo .badge {
  position: absolute;
  right: 0;
  background: #29bbf5;
  color: #fff;
  padding: 3px;
  font-size: 10px;
  width: 18px;
  height: 18px;
}
.topo .botoes > button,
.topo .botoes > div {
  margin: 0 7px;
}
.topo .bmd-btn-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}
.topo .temp-adress {
  display: flex;
  align-items: center;
}
.topo .temp-adress span {
  max-width: 150px;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 0px 0 12px;
}
.topo .matic {
  display: flex;
  align-items: center;
}
.topo .matic img {
  max-width: 32px;
  margin-right: 7px;
}
/*==============MENU==============*/
.menu {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.menu a {
  color: #fff;
}
.navbar-light .navbar-toggler {
  color: #fff;
}
/*=========================MAIN==================*/

main {
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  /* height: 90.8vh; */
  width: 100%;
}
main .content {
  flex: 1 0 auto;
}
.banner {
  width: 100%;
  max-height: 420.33px;
  background: linear-gradient(#08090f 0%, #111322 100%);
  border-radius: 10px;
  padding: 3vw 3vw;
  position: relative;
  margin-bottom: 25px;
  display: flex;
  text-align: right;
  justify-content: space-between;
}
.banner img {
  width: 48vw;
  float: right;
  max-width: 700px;
}
.banner .text {
  width: 48.2vw;
  color: #fff;
  max-width: 549px;
  text-align: left;
  position: relative;
}
.banner .text h2 {
  font-size: 45px;
  color: #fff;
}
.banner .banner-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  text-align: left;
  padding: 25px;
}
.banner .btn {
  width: 100%;
  max-width: 150px;
}
/*=====================CONTENT====================*/
h3 {
  font-family: 'open-sans';
  font-weight: bold;
  font-size: 35px;
  text-align: left;
  color: #fff;
  margin-bottom: 17px;
}
main .highlight-nft {
  margin-bottom: 50px;
}
.highlight-nft .card.nft-item {
  min-height: 341px;
  margin: 0 auto;
}
.card.nft-item {
  width: 100%;
  height: 29vw;
  max-height: 370px;
  border-radius: 8px;
  background: #111322;
  padding: 13px;
  padding-bottom: 0;
  max-width: 286px;
}

.card.nft-item .card-body {
  padding: 0;
  height: 11vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
.highlight-nft .swiper-slide {
  padding: 11px;
}
.card.nft-item .view {
  max-width: 100%;
  border-radius: 4px;
  max-height: 100%;
}
.card.nft-item .card-footer {
  padding: 0;
  display: flex;
  justify-content: space-between;
  padding-top: 5px;
}
.card.nft-item .card-footer img {
  width: 19px;
  height: 23px;
  margin-right: 5px;
}
.card.nft-item .card-footer span {
  font-weight: bold;
  font-size: 15px;
  text-align: left;
  color: #fff;
  display: flex;
  margin-left: 13px;
}
.card.nft-item h5 {
  font-size: 14px;
  color: #fff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.swiper-button-next,
.swiper-button-prev {
  background: #fff;
  width: 37px;
  height: 37px;
  right: 0;
  border-radius: 100px;
  color: #111322;
  display: flex;
  font-size: 20px;
}
.swiper-button-next {
  padding: 8px 14px;
  right: 0;
}
.swiper-button-prev {
  padding: 8px 11px;
  left: 0;
}
.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
  opacity: 0;
}
/*=====================LATEST NFTS====================*/
.list-nft {
  display: grid;
  flex-flow: row wrap;
  grid-column-gap: 4vw;
  grid-row-gap: 33px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-auto-flow: dense;
}

.list-nft .card.nft-item {
}
/* ===================FORMULARIOS======================= */
body select,
body select.form-control-custom,
body select.custom-file-control {
  -webkit-appearance: none;
}

body .custom-control-inline {
  padding: 0;
}
body .custom-control-inline label {
  padding-left: 1.7rem;
  display: flex;
  align-items: center;
  height: 29px;
}

body
  .custom-radio
  .custom-control-input:checked
  ~ .custom-control-label::before {
  background: transparent;
}
body .custom-control-input:checked ~ .custom-control-label::before {
  border-color: #888888;
}
body .custom-control-label::before {
  width: 22px;
  height: 22px;
  background: #fff;
  left: 0;
}
body
  .custom-radio
  .custom-control-input:checked
  ~ .custom-control-label::after {
  background-image: none;
  background: #6d6d6d;
  border-radius: 100%;
  width: 12px;
  height: 12px;
  top: 0;
  bottom: 0;
  left: 5px;
  margin: auto;
}

body
  .custom-checkbox
  .custom-control-input:checked
  ~ .custom-control-label::before {
  background: transparent;
}
body
  .custom-checkbox
  .custom-control-input:checked
  ~ .custom-control-label::after {
  content: '\f00c';
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  font-size: 14px;
  top: 4px;
  bottom: 0;
  left: 5px;
  margin: auto;
}

/* =====================FOOTER================================= */

footer {
  color: #666666;
  display: flex;
  align-items: center;
  padding: 20px;
  justify-content: space-between;
  font-size: 12px;
  right: 0;
  bottom: 0;
  left: 0;
  text-transform: uppercase;
  justify-content: center;
  padding: 20px 0;
  margin-top: 73px;
}
footer .center {
  width: 100%;
  border-top: 1px solid #999999;
  padding: 8px;
  text-align: center;
  justify-content: center;
  display: grid;
  align-items: center;
}
footer img {
  margin-bottom: 5px;
  margin-right: 10px;
  max-width: 130px;
  display: block;
  margin: 0 auto;
}
footer .navbar-nav {
  flex-direction: row;
  width: 100%;
}
footer .navbar-nav .nav-link {
  padding: 13px;
}
/* =====================QUERIES================================= */
@media (max-width: 1200px) {
  .banner .text h2 {
    font-size: 3.8vw;
  }
  .topo .temp-adress {
  }
  .topo .temp-adress img {
    max-width: 21px;
  }
  .topo .temp-adress span {
    font-size: 12px;
  }
  .topo .botoes .btn.btn-outline {
    font-size: 11px;
    padding: 10px;
  }
  .banner .text {
    width: 38vw;
  }
  .banner .text h2 {
    font-size: 3vw;
  }
}
@media (max-width: 992px) {
  .topo {
    flex-flow: row wrap;
    justify-content: center;
  }
  .topo .btn-outline i {
    margin: 0;
  }

  .topo .temp-adress span {
    max-width: 82px;
  }
  .list-nft {
    grid-column-gap: 4vw;
    grid-row-gap: 33px;
    grid-template-columns: 1fr 1fr 1fr;
  }
  .list-nft .card.nft-item {
    height: 37vw;
  }
}
@media (max-width: 767px) {
  .topo {
    flex-direction: column;
    height: auto;
    align-items: start;
  }
  .topo .navbar-nav {
    justify-content: space-between;
    width: 100%;
    padding: 10px;
    display: block;
  }
  .topo .actions {
  }
  .topo .botoes .btn.btn-outline {
    position: absolute;
    top: 6px;
    right: 0;
  }
  .topo .botoes {
    align-items: center;
    padding: 10px 0;
    justify-content: space-between;
  }
  .topo .navbar-collapse {
    position: absolute;
    left: 0px;
    width: 100%;
    top: 115px;
    z-index: 9;
    /* display: block; */
    background: #090a15;
    text-align: center;
  }
  .banner .btn {
    width: 100%;
    max-width: 129px;
  }
  .list-nft {
    grid-template-columns: 1fr 1fr;
  }
  .list-nft .card.nft-item {
    height: 52vw;
  }

  footer img {
    display: block;
    margin: 0 auto;
    margin-bottom: 13px;
  }
  footer .navbar-nav {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  footer .navbar-nav .nav-link {
    padding: 9px;
  }
}
@media (max-width: 480px) {
  .banner {
    display: grid;
    align-items: center;
    text-align: center;
    justify-content: center;
  }
  .banner img {
    margin: 0 auto;
  }
  .banner .text {
    width: 100%;
  }
  .banner .text h2 {
    font-size: 17px;
    text-align: center;
  }
  .banner .banner-footer {
    position: relative;
    padding: 11px 15px;
    justify-content: center;
    display: flex;
  }
}

@media (max-width: 400px) {
  .list-nft {
    grid-template-columns: 1fr;
  }
  .list-nft .card.nft-item {
    height: 113vw;
    max-width: inherit;
  }
}
@media (min-width: 992px) {
}
@media (min-width: 1200px) {
}

@media (min-width: 1700px) {
}
