.banner {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px;
  height: 480px;
  background-image: url('../images/homepage-bg.jpg');
  background-repeat: no-repeat;
  background-position: center -350px;
  border-radius: 20px;
  color: #fff;
}

.banner__title {
  color: #fff;
  font-size: 40px;
  font-weight: 600;
  text-align: center;
}

.banner__description {
  max-width: 1000px;
  margin: 0 auto 60px;
  font-size: 24px;
  font-weight: 500;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .banner {
    height: auto;
  }
  .banner__title {
    font-size: 28px;
  }
  .banner__description {
    font-size: 18px;
    margin-bottom: 50px;
  }
}
