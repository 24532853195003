#WEB3_CONNECT_MODAL_ID {
  z-index: 999;
  position: absolute;
}

#ACCOUNT_HEADER_ID {
  z-index: 900;
}

@font-face {
  font-family: 'Circular Std';
  src: local('Circular Std Book'),
    url(./assets/fonts/CircularStd-Book.woff) format('woff');
}

body {
  font-family: 'Circular Std';
  background-color: #06061b;
  color: #ffffff;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
}

.creator_blockie {
  border-radius: 50%;
  box-shadow: 0 0 0 1px #b5b5b5, 0 0 0 1px #b5b5b5;
  padding: 5px;
}

.avatar-uploader {
  width: auto;
}

.ant-upload.ant-upload-drag {
  height: 100%;
}
.network-banner {
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 70px;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-weight: bold;
  font-size: smaller;
  right: 0;
  text-align: center;
  background: #8247e5;
  border-color: white !important;
  border-radius: 5px;
  padding: 2px;
  height: '100%';
  margin-top: 0px;
}

.site-main {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
}

.content {
  flex: 1 0 auto;
}

/* Antd Overrides */
.ant-btn-lg {
  font-weight: 500;
}
.ant-btn-round,
.ant-btn-round.ant-btn-lg {
  border-radius: 6px;
}
.ant-btn.button--xl {
  height: auto;
  padding: 8px 22px;
  font-size: 20px;
}

.btn-outline-primary:hover,
.btn-outline-primary:focus,
.btn-outline-primary:active,
.btn-outline-primary:active:focus {
  background: #29bbf5;
  font-weight: 700;
  color: #000 !important;
}
/* Utilities */
.container {
  margin: 0 auto;
  padding: 0 40px;
  max-width: 1800px;
}

.text-center {
  text-align: center;
}

.flex-center {
  display: flex;
  align-items: center;
}

h2 {
  color: #e4e4e4;
}

h3.ant-typography,
.ant-typography h3 {
  margin-bottom: 0.5em;
  color: #ffffff;
  font-weight: 600;
  font-size: 24px;
  line-height: 1.35;
}

.ant-typography {
  color: #ffffff;
}

.accountsCotent {
  margin: 0px auto;
  margin-top: 10px;
}

.nfts-grid {
}

.each-asset {
  flex-shrink: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
}
@media (max-width: 1079px) {
  .wallet-info {
    z-index: 99;
    position: fixed;
    text-align: right;
    right: 0px;
    bottom: 0px;
    padding: 7;
    width: 100%;
    height: 50px;
    background-color: rgb(21, 43, 68) !important;
    border-radius: 16;
  }
  .burner-wallet {
    margin: 3px;
  }
}

@media (min-width: 1080px) {
  .wallet-info {
    z-index: 99;
    position: absolute;
    text-align: right;
    right: 10px;
    top: 12px;
    padding: 7;
    background-color: 'rgb(21, 43, 68)';
  }
}

.allnfts-main {
  margin-top: 10px;
  justify-content: center;
  max-width: 1250px;
  margin: 0 auto;
}

.allnfts-main h1 {
  color: mix(#fff, #e91e63, 85%);
  margin-top: 10px;
  font-size: xx-large;
  font-weight: 900;
}

.holdings {
  max-width: 1250px;
  text-align: center;
  margin: 15px auto;
}

.accountsCotent {
  max-width: 1250px;
  text-align: center;
  margin: 15px auto;
}

.createfile {
  text-align: left;
  max-width: 620px;
  margin: 0 auto;
}
.createfile-right {
  margin-left: 10px;
  margin-right: 10px;
}
.ant-switch {
  background-color: red;
  border-color: white !important;
  margin: 5px;
}

.ant-drawer-content {
  background-color: #06061b;
}

.ant-drawer-header {
  position: relative;
  padding: 16px 24px;
  background: rgb(21, 43, 68) !important;
  border-bottom: 1px solid #f0f0f0;
  border-radius: 2px 2px 0 0;
}

.ant-drawer-title {
  margin: 0;
  color: #f0f0f0;
  font-weight: 500;
  font-size: 24px;
  line-height: 22px;
}

.ant-form-item-label label {
  font-size: 16px;
  color: #73ad21;
}

.eachCard:hover {
  box-shadow: 0 0 11px #fff;
}

.footer-nav li button,
.footer-nav li a {
  padding: 5px 10px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 40px;
}
